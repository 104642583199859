import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MongoService} from '../shared/mongo.service';
import {GlobalService} from '../shared/variables.service';
import {IProject}from './project';
import { DOCUMENT } from '@angular/platform-browser';
import { trigger,style,transition,animate,keyframes,query,stagger } from '@angular/animations';

@Component({
  // selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  animations: [

  trigger('listAnimation', [
    transition('* => *', [

      query(':enter', style({ opacity: 0 }), {optional: true}),

      query(':enter', stagger('30ms', [
        animate('0.1s ease-in', keyframes([
          style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
          style({opacity: .5, transform: 'translateY(35px)',  offset: 0.3}),
          style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
        ]))]), {optional: true})
    ])
  ])

]
})
export class ProjectComponent implements OnInit {

  public innerHeight: number = window.innerHeight;
  pageTitle: string = 'Projects List';
  paginationInProcess: boolean=false;
  listFilter: string;
  errorMessage: string;
  project: IProject;
  sub: any;
  params={type:'', page:0, subscription_id:''};


     constructor(
       public _globalService: GlobalService,
       private _mongoService: MongoService,
       private route: ActivatedRoute,
       private router: Router,
       @Inject(DOCUMENT) private document: Document
     ){}

    //  toggleImage(): void {
    //    this.showImage = !this.showImage;
    //  }



     ngOnInit(): void{
      this._globalService.setLoading(true);


      this.sub = this.route
        .queryParams
        .subscribe(params => {
          // Defaults to 0 if no query param provided.
          console.log(params);
          this._globalService.params['type']= params.type;
          this._globalService.params['page']=0

          this.getList(this._globalService.params.page);
          // = +params['type'] || 0;
        });



      setTimeout(() => {
        this._globalService.setLoading(false);
      }, 2000);

      //  if(!this.feed){
      //    this.getPage(0);
      //  }


     }



     concatResults(obj): void{
      // this.project.results= this.project.results.concat(obj.results);
      // this.project.next_page=obj.next_page;
      // this.project.page=obj.page;
      // this.project.total_pages=obj.total_pages;
      // this.project.prev_page=obj.prev_page;
     }

     getList(page): void{
       let query = {page:0,collection:'project', type:'' };
       if(this._globalService.params.type){
         console.log('type:', this._globalService.params.type);
         query.type=this._globalService.params.type;
       }

       this._mongoService.getList(page,'project',query.type)
       .subscribe(
         project=>{
           if(page==0){
             this.project=project;
             console.log(this.project.length)
             this._globalService.setLoading(false);
           }else{this.concatResults(project)}
         },
         error=>{
           this.errorMessage=error;
           this._globalService.setLoading(false);
         }
       );
     }



     ngOnDestroy() {
       this.sub.unsubscribe();
     }



    //  @HostListener("window:scroll", [])
    //   onWindowScroll() {
    //     let number = this.document.body.scrollTop;
    //     console.log(window.innerHeight);
    //     var body = document.body, html = document.documentElement;
    //     var docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    //     var windowBottom = window.innerHeight + window.pageYOffset;
    //     if ((windowBottom >= docHeight) &&(this.paginationInProcess==false)) {
    //         // alert('bottom reached');
    //         if(this.project){
    //           if((this.project.page+1)<this.project.total_pages){
    //             this.getList(this.project.next_page);
    //           }
    //         }
    //     }
    //   }

}
