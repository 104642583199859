import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {ProjectModule} from './project/project.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { NavComponent } from './nav/nav.component';
import { LogoComponent } from './logo/logo.component';
import { LogotypeComponent } from './logo/logotype.component';
import { GlobalService } from './shared/variables.service';
import { CubeComponent } from './cube/cube.component';
import { ServiceComponent } from './service/service.component';
import { ProcessedComponent } from './service/processed/processed.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { ProfileComponent } from './user/profile/profile.component';
import { AuthGuardService } from './shared/auth-guard.service';


@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ContactComponent,
    NavComponent,
    LogoComponent,
    LogotypeComponent,
    CubeComponent,
    ServiceComponent,
    ProcessedComponent,
    UserComponent,
    LoginComponent,
    RegisterComponent,
    ProfileComponent
  ],
  imports: [
    FormsModule,                               // <========== Add this line!
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    ProjectModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      {path: 'about', component: AboutComponent},
      {path: 'contact', component: ContactComponent},
      {path: 'service', component: ServiceComponent, canActivate: [AuthGuardService]},
      {path: 'login', component: LoginComponent },
      {path: 'register', component: RegisterComponent },
      {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService]},
      // {path:'project', redirectTo:'project/'+auth.getUserDetails(), pathMatch: 'full'},
      {path:'', redirectTo:'project', pathMatch: 'full'},
      {path: '**', redirectTo: '', pathMatch: 'full'}
    ]),
  ],
  providers: [GlobalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
