import { Component, OnInit } from '@angular/core';
import { AuthenticationService, TokenPayload } from './../../shared/authentication.service';
import { Router } from '@angular/router';
import {GlobalService} from '../../shared/variables.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  credentials: TokenPayload = {
    email: '',
    password: ''
  };
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private _globalService: GlobalService
  ) { }

  ngOnInit() {
  }

  register() {
    this.auth.register(this.credentials).subscribe((data) => {
      console.log(data);
      this.router.navigateByUrl('/profile');
    }, (err) => {
      console.error(err);
    });
  }

}
