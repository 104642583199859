import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserDetails } from '../../shared/authentication.service';
import {GlobalService} from '../../shared/variables.service';
// import {Plaid} from 'plaid_front';
import { HttpService } from '../../shared/http.service';
// import { DynamicScriptLoaderService } from '../../shared/dynamic-script-loader.service';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  details: UserDetails;


  constructor(
    private auth: AuthenticationService,
    private _globalService :GlobalService,
    private _http:HttpService,
    // private dynamicScriptLoader: DynamicScriptLoaderService,
    // private _plaid: Plaid
  ) {

  }

  ngOnInit() {
    this.auth.profile().subscribe(user => {
      this.details = user;
      console.log(this.details);
      this._globalService.setLoading(false);

        this.getBank(this.details, this._http);
    }, (err) => {
      console.error(err.message);
      this._globalService.throwErr(err.message);
      console.error(err);
    });

    // this.loadChartScripts();



  }

  // loadChartScripts() {
  //   // You can load multiple scripts by just providing the key as argument into load method of the service
  //   this.dynamicScriptLoader.load('Plaid').then(data => {
  //     // Script Loaded Successfully
  //   console.log(data);
  //   }).catch(error => console.log(error));
  // }

  // addBankAccount=function(data){
  //
  //
  // }

  getBank(user, http){

    var form = document.getElementById('bank-form');
    form.addEventListener('submit', (event)=> {
      var plaid_env;
      if(this._globalService.env=='production'){
        plaid_env=this._globalService.env
      }else{
        plaid_env='sandbox'
      }

        var linkHandler = Plaid.create({
        selectAccount: true,
        env: plaid_env,
        clientName: 'Client Name',
        key: '0ca9b35ee7c5f64640eced7d9e415a',
        product: ['auth'],
        onLoad: function() {
          // The Link module finished loading.
        },
        onSuccess: function(public_token, metadata) {
          // The onSuccess function is called when the user has
      // successfully
          // authenticated and selected an account to use.
          //
          // When called, you will send the public_token and the selected
          // account ID, metadata.account_id, to your backend app server.
          //
          // sendDataToBackendServer({
          //   public_token: public_token,
          //   account_id: metadata.account_id
          // });
          console.log('Public Token: ' + public_token);
          console.log('Customer-selected account ID: ' + metadata.account_id);
          var data = {
            stripe_id: user.stripe.id,
            account_id:metadata.account_id,
            public_token: public_token,
            user_id:user._id
          }
          http.addBankAccount(user._id, data).subscribe((data) => {
            console.log(data);
            // this.router.navigateByUrl('/profile');
          }, (err) => {
            console.error(err);
            this._globalService.error={status:true, message:err.message}
          });
        },
        onExit: function(err, metadata) {
          // The user exited the Link flow.
          if (err != null) {
            // The user encountered a Plaid API error prior to exiting.
          }
          // metadata contains information about the institution
          // that the user selected and the most recent API request IDs.
          // Storing this information can be helpful for support.
        },
      });

        // Trigger the standard Institution Select view
        document.getElementById('linkButton').onclick = function() {
          linkHandler.open();
        };
    });
  }









}
