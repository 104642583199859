import { Component, OnInit } from '@angular/core';
import {GlobalService} from '../shared/variables.service';


import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-logotype',
  templateUrl: './logotype.component.html',
  styleUrls: ['./logotype.component.scss'],
  animations: [
    trigger('splashState', [
      state('0', style({
        opacity:'0'
      })),
      state('1',   style({
        opacity:'1',
        right:'20px',
        bottom:'20px',
        top:'auto',
        left:'auto'
      })),
      transition('0 => 1', animate('2000ms ease-in')),
      transition('1 => 0', animate('2000ms ease-out'))
    ])
  ]
})
export class LogotypeComponent implements OnInit {

  constructor(
    private _globalService: GlobalService
  ) { }

  ngOnInit() {
  }

}
