import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http';
// import {Feed} from './feed';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

@Injectable()
export class HttpService {

   private _getUrl = 'api/prismic/get/all';

  constructor(private _http:HttpClient) { }


  getFeed(type, page): Observable<any> {
    console.log(type, page)
   return this._http.get(this._getUrl+'?page='+page+'&type='+type)
     // .map((response: Response)=> <any> response.json())
     // .do(data => console.log(data))
     // .catch(this.handleError);
 }

 getSingle(type, uid): Observable<any> {
    return this._http.get('api/prismic/get/single?type='+type+'&uid='+uid)
  }

// submitUser(type, data): Observable<any> {
//  return this._http.put('/api/stripe/user/new/', data)
// }

submitSubscription(type, data): Observable<any> {
 return this._http.put('/api/stripe/user/'+data.user_id+'/subscription/new?type='+type, data)
}

addBankAccount(user_id, data): Observable<any> {
 return this._http.post('/api/stripe/user/'+user_id+'/payment/new/bank', data)
}

// newUserStripe(type, data): Observable<any> {
//  return this._http.put('/api/stripe/user/new/', data)
// }



getJson(url): Observable<any> {
 return this._http.get(url, {observe: 'response'})
}

initialize(url): Observable<any>{
  return this._http.get(url, {observe: 'response', responseType: 'text',})
}



//  private handleError(error: Response){
//   console.log("error", error);
//   return Observable.throw(error.json().error || 'Server error');
// }

}
