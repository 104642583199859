import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import { Location } from '@angular/common';
import {GlobalService} from '../shared/variables.service';
import { AuthenticationService } from './../shared/authentication.service';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    trigger('navLiState', [
      state('0', style({
        height:'0px',
        position: 'absolute'
      })),
      state('1',   style({
        height:'50px',
        position: 'relative'
      })),
      transition('0 => 1', animate('500ms ease-in')),
      transition('1 => 0', animate('500ms ease-out'))
    ]),
    trigger('navUlState', [
      state('0', style({
      })),
      state('1',   style({
      })),
      transition('0 => 1', animate('500ms ease-in')),
      transition('1 => 0', animate('500ms ease-out'))
    ])
  ]
})
export class NavComponent implements OnInit {

   @Input() isNav : number = 0;
   currentBase:any;
   route: string;
   isLoggedIn: boolean;
   user:any;
   getSegment: Function= (url, index) =>{
       return url.replace(/^https?:\/\//, '').split('/')[index];
    };

   constructor(
     location: Location,
     router: Router,
     private activatedRoute: ActivatedRoute,
     private _globalService: GlobalService,
     public auth: AuthenticationService
   ) {
     router.events.subscribe((val) => {
       if(activatedRoute.snapshot.children[0]&&activatedRoute.snapshot.children[0].url[0]){
         _globalService.path[0]=activatedRoute.snapshot.children[0].url[0].path;
         console.log(_globalService.path);
       }

       if(location.path() != ''){
         this.route = location.path();
       }
       else {
         this.route = 'home'
       }
       this.isLoggedIn=auth.isLoggedIn();
       this.user=auth.getUserDetails();
     });
   }

  ngOnInit() {


  }

  openNav() {
    this.isNav=1;
  }

  closeNav() {
    setTimeout(() => {
      this.isNav=0;
    }, 3000);
  }

}
