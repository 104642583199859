import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MongoService} from './mongo.service';
import {HttpService} from './http.service';
import {DynamicScriptLoaderService} from './dynamic-script-loader.service';
import {GlobalService} from './variables.service';
import { SafePipe } from './safe-url.pipe';

@NgModule({
  declarations: [
  SafePipe],
  providers:[MongoService, GlobalService, HttpService, DynamicScriptLoaderService],
  imports:[CommonModule],
  exports:[
    CommonModule,
    FormsModule,
    SafePipe
  ]

})

export class SharedModule{

}
