import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';

import { ProjectComponent } from './project.component';
import { ProjectGuard } from './project-guard.service';
import { MongoService } from '../shared/mongo.service';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DetailComponent } from './detail/detail.component';
import { DetailGuard } from './detail/detail.service';
import { SafePipe } from './../shared/safe-url.pipe';

@NgModule({
    declarations: [
        ProjectComponent,
        DetailComponent
        // ProductFilterPipe
    ],
    imports: [
        SharedModule,
        BrowserAnimationsModule,
        RouterModule.forChild([
          { path: 'project/:id', canActivate: [ DetailGuard ], component: DetailComponent },
          { path: 'project', component: ProjectComponent }

        ])
    ],
    providers: [
        ProjectGuard,
        DetailGuard,
        SafePipe
    ]
})
export class ProjectModule {}
