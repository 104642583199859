import { Component, OnInit } from '@angular/core';
import {MongoService} from '../../shared/mongo.service';
import {GlobalService} from '../../shared/variables.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IDetail} from './detail';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  detail:IDetail;
  errorMessage:any;

  constructor(
    private _mongoService: MongoService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _globalService: GlobalService
  ) { }

  ngOnInit() {
    this._globalService.setLoading(true);
    let id = this._route.snapshot.params['id'];
    console.log("id:"+id);
    // this.pageTitle += `: ${id}`;
    this._mongoService.getSingle('project',id)
    .subscribe(
      data=>{this.detail=data; this._globalService.setLoading(false);},
      error=>this.errorMessage=<any>error
    )
  }

}
