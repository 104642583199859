import { Component, HostBinding,OnInit } from '@angular/core';
import {slideInDownAnimation} from './animations';
import {GlobalService}from './shared/variables.service';
import {HttpService}from './shared/http.service';

// import {}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInDownAnimation ]
})
export class AppComponent {


  title = 'app works!';

  @HostBinding('@routeAnimation') routeAnimation = true;
  @HostBinding('style.display')   display = 'block';

  constructor(
    private _globalService: GlobalService,
    private _httpService: HttpService
  ) {}

  ngOnInit() {
    this.initialize();
  }



  initialize(){
        this._httpService.initialize('/initialize')
        .subscribe(
          data=>{
            console.log("header env:", data.headers.get('env'));
            var env = data.headers.get('env');
            this._globalService.setEnv(env);
          },
          error=>{
            console.log(error)
            this._globalService.error={status:true, message:error.message}
          }
        );
    }

}
