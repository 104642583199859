import { Component, OnInit } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { Service, Processed}    from './service';
import { ActivatedRoute, Router } from '@angular/router';
import {GlobalService} from '../shared/variables.service';
import { AuthenticationService, UserDetails } from '../shared/authentication.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent {
  service:Service;
  submitted = false;
  params:any;
  user:any;
  processed:Processed={value:false, error:false, data:false, loading:false};
  sessions=[100,500,1000,3000, 5000, 10000, 20000];
  credits=[10,20,30,40,50];
  subscription_type:Array<any>=[];
// $scope.ssl=false;
  model:Service={
    user_id:'',
    stripe_id:'',
    paymentToken:'',
    digital:{
      subscription_id:'plan_DvqIOQvaLYY1yl',
      rate:100,
      credits:10,
      name:'',
      description:'ELIA LLC. offers digital services to its clients. In this page every digital plan is outlined to make sure the client selects and subscribes to the service that most fits its needs'
    },
    hosting:{
          subscription_id:'plan_DxQPMtFSfJLWZI',
          rate:100,
          sessions: 500,
          ssl:false,
          name:'',
          description:'ELIA LLC. offers hosting services to its clients. In this page every hosting plan is outlined to make sure the client selects and subscribes to the service that most fits its needs. A client is hereby asked to select its monthly sessions and if an SSL encryption is required. While monthly sessions can be viewed via Google Analytics, the need for encryption is to be discussed with ELIA LLC.'
        }
}

  constructor(
    private _httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private _globalService: GlobalService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.startStripe();
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided. // = +params['type'] || 0;
        this._globalService.params.type= params.type;
        if(params.subscription_id){
          this._globalService.params.subscription_id= params.subscription_id;
        }
        this.getJsonData(params.type);
      });

      this.auth.profile().subscribe(data => {
        this.user = data;
      }, (err) => {
        console.error(err);
      });
  }



  getJsonData(type){
        this._httpService.getJson('/data/'+type)
        .subscribe(
          data=>{
            console.log("header env:",data.headers.get('env'));
            var env = data.headers.get('env');
            var subscription_id;
            this.subscription_type=data.body;
            // this.readQuery(type);
            console.log('getJsonData');
            console.log(data);
            this._globalService.setLoading(false);
            this._globalService.setEnv(env);

            if(env=='staging'){
              subscription_id=this.subscription_type[0].subscription_id_test
            }else{
              subscription_id=this.subscription_type[0].subscription_id
            }
            this.model[this._globalService.params.type].subscription_id=subscription_id;
            this.router.navigate(['service'], { queryParams: { type:this._globalService.params.type , subscription_id:subscription_id  } });

          },
          error=>{
            this._globalService.error={status:true, message:error}
          }
        );
    }

  readQuery(type){
    console.log("readQuery");
    for (let i in this.subscription_type){
      if(this.subscription_type[i].subscription_id==this._globalService.params.subscription_id){
        console.log(i);
        if(type=='hosting'){
          this.model.hosting.ssl = this.subscription_type[i].ssl;
          this.model.hosting.rate = this.subscription_type[i].rate;
          this.model.hosting.sessions = this.subscription_type[i].sessions;
          // this.model.hosting.subscription_id = this._globalService.params.subscription_id;
          this.model.hosting.name = this.subscription_type[i].name;
        }else if(type=='digital'){
          this.model.digital.credits = this.subscription_type[i].credits;
          this.model.digital.subscription_id = this._globalService.params.subscription_id;
          this.model.digital.rate = this.subscription_type[i].rate;
          this.model.digital.name = this.subscription_type[i].name;
        }

      }
    }
  }





  watchThis_h(){
    console.log('watchthis h');
    var sessions = this.model.hosting.sessions;
    console.log('sessions: '+sessions);
    console.log(this.model);
    var ssl = this.model.hosting.ssl;
    for (let i in this.subscription_type){
      console.log(this.subscription_type[i].sessions, this.model.hosting.sessions);
      if((this.subscription_type[i].sessions == sessions) && (this.subscription_type[i].ssl==ssl)){
        this.model.hosting.rate = this.subscription_type[i].rate;
        this.model.hosting.name = this.subscription_type[i].name;
        // this.model.hosting.subscription_id = this.subscription_type[i].subscription_id;
        console.log(this.model.hosting);
        this.router.navigate(['service'], { queryParams: { type:this._globalService.params.type , subscription_id: this.subscription_type[i].subscription_id } });
      }
    }
  }

  //watcher service
watchThis_s(){
  console.log('watchThis_s');
  var credits = this.model.digital.credits;
  console.log(this.model.digital.credits);

  // var marketing = this.model.digital.marketing;
  for (var i in this.subscription_type){
    if((this.subscription_type[i].credits == credits)){
      console.log(this.model.digital.credits, this.subscription_type[i].credits);
      // && ($scope.subscription_type[i].marketing==marketing)
      // $scope.subscribe.subscription_id = $scope.subscription_type[i].subscription_id;
      this.model.digital.rate = this.subscription_type[i].rate;
      this.model.digital.rate = this.subscription_type[i].rate;
      this.model.digital.name = this.subscription_type[i].name;
      console.log(this.model.digital);

    }
  }
}




  // submitUser():void{
  //   this._globalService.setLoading(true);
  //   console.log(this.model);
  //   this._httpService.submitUser(this._globalService.params.type, this.model)
  //   .subscribe(
  //     response=>{
  //       console.log(response);
  //       if(response.statusCode==200){
  //         this.processed={value:true, error:false, data:response, loading:false};
  //       }else{
  //         console.log("form failed!");
  //         console.log(response);
  //         this.processed={value:true, error:true, data:response, loading:false};
  //         this._globalService.error={status:true, message:response};
  //       }
  //     },
  //     error=>{
  //       this._globalService.error={status:true, message:error};
  //       this._globalService.setLoading(false);
  //     }
  //   );
  // }



  submitSubscription=function(paymentToken){
    this._globalService.setLoading(true);
    this.model.user_id=this.user._id;
    this.model.stripe_id=this.user.stripe_id;
    this.model.paymentToken=paymentToken.id;
    this._httpService.submitSubscription(this._globalService.params.type, this.model)
    .subscribe(
      response=>{
          this.processed={value:true, error:false, data:response, loading:false};
          console.log('response');
          console.log(response);
          this.router.navigateByUrl('/profile');
      },
      error=>{
        console.log("form failed!");
        this.processed={value:true, error:true, data:error, loading:false};
        this._globalService.error={status:true, message:error};
        this._globalService.setLoading(false);
      }
    );
  }

  startStripe(){
    var stripe;
    console.log('env:'+this._globalService.env);
    if(this._globalService.env=="staging"){
      stripe = Stripe('pk_test_ncLatR4jVg59jtByn1WiiXlO');
    }else if(this._globalService.env="production"){
      stripe = Stripe('pk_live_SgyqoTT2dAnibLpAJmNunMwt');
    }

    // Create an instance of Elements.
    var elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    let card:any = elements.create('card', {style: style});
    console.log(card);

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');

    // Handle real-time validation errors from the card Element.
    card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });


    // Handle form submission.
    var form = document.getElementById('payment-form');
    form.addEventListener('submit', (event)=> {
      event.preventDefault();
      stripe.createToken(card).then((result)=> {
        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // Send the token to your server.
          console.log(result.token);
          this.submitSubscription(result.token);
        }
      });

    });

  }








}
