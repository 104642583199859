import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable()
export class DetailGuard implements CanActivate {

  constructor(private _router: Router){}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let id = +route.url[1].path;
    console.log(route.url);
    if(id){
       // isNaN(id) || id<1
      // alert('Invalid document Id');
      // this._router.navigate(['/document']);
      // return true;
    }
    return true;
  }
}
