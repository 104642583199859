import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http';
// import {Feed} from './feed';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

@Injectable()
export class MongoService {

   private _getUrl = 'api/mongo/get/all';

  constructor(private _http:HttpClient) { }


  getList(page,collection,type): Observable<any> {
    console.log('type:'+type)
   return this._http.get('api/mongo/get/all?page='+page+'&collection='+collection+'&type='+type)
     // .map((response: Response)=> <any> response.json())
     // .do(data => console.log(data))
     // .catch(this.handleError);
 }

 getSingle(collection, id): Observable<any> {
  return this._http.get('api/mongo/get/single?collection='+collection+'&id='+id)
    // .map((response: Response)=> <any> response.json())
    // .do(data => console.log(data))
    // .catch(this.handleError);
}

//  private handleError(error: Response){
//   console.log("error", error);
//   return Observable.throw(error.json().error || 'Server error');
// }

}
