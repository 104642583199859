import {Injectable} from "@angular/core";
import {Location} from '@angular/common';
import {Params} from './params'
import {Error} from './error'
@Injectable()
export class GlobalService {
    public pageLoading=true;
    public params:Params={type:null, page:0, subscription_id:null};
    public path:Array<any>=[];
    public error:Error={status:false, message:''};
    public env:String

    constructor(private _location: Location) {}

    setLoading(val) {
        this.pageLoading = val;
    }
    setEnv(val) {
      this.env = val;
    }

    getValue(val) {
        return this.pageLoading;
    }
    goBack(){
      this._location.back();
    }

    throwErr(message){
      this.error={status:true, message:message};
    }



}
